import { useApolloClient } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { ChannelQuery, useAuth } from 'teddly-sdk';
import { LocalStorageItems } from 'teddly-sdk/lib/helpers';

export type ChannelType = ChannelQuery['channel'];

const ChannelContext = React.createContext<{
  isChannelSelected: boolean;
  selectedChannel: ChannelType;
  selectChannelByPostalCode: (
    postalCode: string,
  ) => Promise<ChannelType | null>;
}>(null);

export function ChannelProvider({ children }: { children: React.ReactNode }) {
  const client = useApolloClient();
  const {
    selectChannelByPostalCode: getChannelByPostalCodeQuery,
    authenticated,
    channelDetails,
    signOut,
  } = useAuth();
  const forceUpdate = React.useReducer(() => ({}), {})[1];


  const [urlChannel, setUrlChannel] = useState<ChannelType | null>(null);
  const localStringifyChannel = localStorage.getItem(LocalStorageItems.CHANNEL);

  const getLocalStringifyChannel = () =>
    localStorage.getItem(LocalStorageItems.CHANNEL);

  const parseStringifyChannel = (stringify: string): ChannelType | null => {
    return stringify ? (JSON.parse(stringify) as ChannelType) : null;
  };

  const selectedChannel = urlChannel
    ? urlChannel
    : localStringifyChannel
      ? parseStringifyChannel(localStringifyChannel)
      : null

  const selectChannelByPostalCode = async (
    postalCode: string,
  ): Promise<ChannelType | null> => {
    try {
      const response = await getChannelByPostalCodeQuery(postalCode);
      forceUpdate()
      if (
        response?.data &&
        parseStringifyChannel(getLocalStringifyChannel())?.id ===
          response?.data?.id
      ) {
        client.reFetchObservableQueries();
        return response.data as ChannelType;
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  };
  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      client.reFetchObservableQueries();
    }
  }, [authenticated, localStringifyChannel]);

  const getAndSetChannelDetails = async (
    id: string,
    saveInLocal: boolean = true,
  ) => {
    const details = await channelDetails({ id: id, saveInLocal: saveInLocal });
    return details;
  };

  const getChannelIdFromUrl = () => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const channelFromUrl = params.get('channel');
    return channelFromUrl;
  };

  const getCurrentChannelDetails = async () => {
    const urlChannel = getChannelIdFromUrl();
    if (
      selectedChannel?.id &&
      (!urlChannel || urlChannel == selectedChannel.id)
    ) {
      getAndSetChannelDetails(selectedChannel?.id, true);
    }
  };

  const getSaveChannelInLocal = (): boolean => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const saveChannelInLocal = params.get('selected');
    return saveChannelInLocal == 'true';
  };

  const changeChannelByUrl = async (channelId: string) => {
    if (authenticated) {
      await signOut();
    }
    const saveInLocal = getSaveChannelInLocal();
    const res = await getAndSetChannelDetails(channelId, saveInLocal);
    setUrlChannel(res.data);
  };

  React.useEffect(() => {
    const channelFromIrl = getChannelIdFromUrl();
    if (
      channelFromIrl &&
      (!selectedChannel || selectedChannel?.id !== channelFromIrl)
    ) {
      changeChannelByUrl(channelFromIrl);
    } else {
      setUrlChannel(null);
    }
  }, [window.location.search]);

  React.useEffect(() => {
    getCurrentChannelDetails();
  }, []);

  return (
    <ChannelContext.Provider
      value={{
        isChannelSelected: !!selectedChannel?.id,
        selectedChannel: selectedChannel,
        selectChannelByPostalCode,
      }}>
      {children}
    </ChannelContext.Provider>
  );
}

export const useChannelContext = () => {
  const context = React.useContext(ChannelContext);

  if (!context)
    throw new Error('ChannelContext was used outside of the ChannelProvider');

  return context;
};
