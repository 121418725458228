/**
 * TODO:
 * 1. create data-testids map (ids)
 * 2. Create getter for all of the required elements
 * 3. Create basic actions - click (button) input (type/select) assert (varifying text fields)
 */

import Utils from '../../utils/utils.cy';
import { BasePageObjectModel } from '../../BasePageObjectModel';

export const ids = {
  accountActionsButton: 'account-actions',
  dialog: 'dialog',
  heading: 'signup-heading',
  emailInput: 'signup-email',
  firstNameInput: 'signup-first-name',
  lastNameInput: 'signup-last-name',
  phoneInput: 'signup-phone',
  passwordInput: 'signup-password',
  submitButton: 'signup-submit',
  cancelButton: 'signup-cancel',
  resendEmailButton: 'signup-resend-email',
  socialLoginButton: 'signup-social-login',
};

export default class SignUpDialogPage extends BasePageObjectModel {
  url: string;
  title: string;

  constructor() {
    super('/index.php?route=extension/maza/blog/home', 'Blog');
  }

  get dialog() {
    return cy.get(Utils.formatDataTestIdSelector(ids.dialog));
  }

  get heading() {
    return cy.get(Utils.formatDataTestIdSelector(ids.heading));
  }

  get emailInput() {
    return cy.get(Utils.formatDataTestIdSelector(ids.emailInput));
  }

  get firstNameInput() {
    return cy.get(Utils.formatDataTestIdSelector(ids.firstNameInput));
  }

  get lastNameInput() {
    return cy.get(Utils.formatDataTestIdSelector(ids.lastNameInput));
  }

  get phoneInput() {
    return cy.get(Utils.formatDataTestIdSelector(ids.phoneInput));
  }

  get passwordInput() {
    return cy.get(Utils.formatDataTestIdSelector(ids.passwordInput));
  }

  get submitButton() {
    return cy.get(Utils.formatDataTestIdSelector(ids.submitButton));
  }

  get cancelButton() {
    return cy.get(Utils.formatDataTestIdSelector(ids.cancelButton));
  }

  getErrorMessage(field: Cypress.Chainable<HTMLElement>) {
    return field.closest('label').find('label');
  }

  getRelatedLabel(inputField) {
    return inputField.closest('label').find('label');
  }

  get successMessage() {
    return cy.get('p');
  }

  assertHeadingText(expectedText) {
    return this.heading.should('contain', expectedText);
  }

  typeEmail(email) {
    return this.emailInput.type(email, { force: true });
  }

  typeFirstName(firstName) {
    return this.firstNameInput.type(firstName, { force: true });
  }

  typeLastName(lastName) {
    return this.lastNameInput.type(lastName, { force: true });
  }

  typePhone(phone) {
    return this.phoneInput.type(phone, { force: true });
  }

  typePassword(password) {
    return this.passwordInput.type(password, { force: true });
  }

  clickSubmitButton() {
    return this.submitButton.click({ force: true });
  }

  clickCancelButton() {
    return this.cancelButton.click({ force: true });
  }

  assertErrorMessage(field, expectedErrorMessage) {
    return field.parents(':lt(2)').next('label').contains(expectedErrorMessage);
  }

  assertSuccessMessage(expectedSuccessMessage) {
    return this.successMessage.should('contain', expectedSuccessMessage);
  }
}
