import Utils from '../../utils/utils.cy';

export const ids = {
  dialog: 'dialog',
  header: 'header',
  headingTitle: 'heading-title',
  backdrop: 'backdrop',
  headerCloseButton: 'header-close-icon',
};

export default class DialogPage {
  get dialog() {
    return cy.get(Utils.formatDataTestIdSelector(ids.dialog));
  }

  get headingTitle() {
    return cy.get(Utils.formatDataTestIdSelector(ids.headingTitle));
  }

  get backdrop() {
    return cy.get(Utils.formatDataTestIdSelector(ids.backdrop));
  }

  get closingButton() {
    return cy.get(Utils.formatDataTestIdSelector(ids.headerCloseButton));
  }

  assertHeadingText(expectedText) {
    return this.headingTitle.should('contain', expectedText);
  }

  clickClosingButton() {
    return this.closingButton.click({ force: true, multiple : true });
  }

  clickBackdrop() {
    return this.backdrop.click({ force: true });
  }
}
