/**
 * TODO:
 * 1. create data-testids map (ids)
 * 2. Create getter for all of the required elements
 * 3. Create basic actions - click (button) input (type/select) assert (varifying text fields)
 */

import Utils from '../../utils/utils.cy';

export const ids = {

  shoppingListsTable: 'shopping-lists-table',
  createNewListButton: 'create-new-list-button',
  title: 'title',
  shoppingListNameInput: 'shopping-list-name-input',
  saveListButton: 'save-list-button',
  snackbar: 'snackbar',
 
};

export default class MyShoppingListsPage {




  get snackbar() {
    return cy.get(Utils.formatDataTestIdSelector(ids.snackbar));
  }
  
  get saveListButton() {
    return cy.get(Utils.formatDataTestIdSelector(ids.saveListButton));
  }
  get shoppingListNameInput() {
    return cy.get(Utils.formatDataTestIdSelector(ids.shoppingListNameInput));
  }

  get title() {
    return cy.get(Utils.formatDataTestIdSelector(ids.title));
  }

  get createNewListButton() {
    return cy.get(Utils.formatDataTestIdSelector(ids.createNewListButton));
  }
  
  get shoppingListsTable() {
    return cy.get(Utils.formatDataTestIdSelector(ids.shoppingListsTable));
  }

  clickSaveListButton() {
    return this.saveListButton.click({ force: true, multiple: true });
  }
  clickCreateNewListButton() {
    return this.createNewListButton.click({ force: true, multiple: true });
  }
 
  assertSnackbar(expectedMessage: string) {
    return this.snackbar.should('be.visible').contains(expectedMessage, {
      matchCase: false,
    });
  }

  clickTableRow(index) {
    
    cy.get('.TableRow-module_tableRow__2SXFB').then($rows => {
      const row = $rows[index];
      const clickRow = () => {
        if (!Cypress.dom.isHidden(row) && Cypress.dom.isAttached(row)) {
          cy.wrap(row).click();
        } else {
          setTimeout(clickRow, 100);
        }
      };
      clickRow();
    });
    
  }

  

  
  

}
