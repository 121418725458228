import { useAuth } from 'teddly-sdk';
import useWindowDimensions, {
  scrollToTheTopOfTheElement,
} from '@hooks/useWindowDimension';

import DeliveryInHeader from '@components/Pages/Checkout/components/Delivery/DeliveryInHeader';
import { useCartContext } from '@context/CartContext';
import { SearchBar } from '@components/Molecules/Search';
import {
  BottomNavBar,
  Header as HeaderComponent,
  IconButton,
  ArrowLeft,
  Dialog,
  SearchFieldVariantEnum,
  Button,
  ButtonSizeEnum,
  ButtonVariantEnum,
  User,
  X,
  Menu,
} from '@teddly/teddly-ui-components';

import { HeaderStatesProps, useRoutesContext } from '@context/RoutesContext';
import React, { useRef, useState } from 'react';

import { ids } from '../../../../cypress/pages/navbars/navbarPage.cy';
import { ids as pageLayoutIds } from '../../../../cypress/pages/page-layout/PageLayoutPage.cy';
import { ids as headerIds } from '../../../../cypress/pages/page-layout/HeaderPage.cy';
import { useHomePageContext } from '@context/HomePageContext';
import { usePageLayoutContext } from '@context/PageLayoutContext';
import { useChannelContext } from '@context/ChannelContext';

export default function Header() {
  const {
    isCurrentPage,
    pages,
    navigateTo,
    back,
    router,
    getCurrentPageHeaderStates,
  } = useRoutesContext();
  const { authenticated, user } = useAuth();
  const { defaultCheckoutCount } = useCartContext();
  const {
    dispatch,
    openLoginDialog,
    isSidebarNavOpen,
    openSidebarNav,
    isPageSidebarNavOpen,
    closeSidebarNav,
    setLoading,
    isLoading,
    showOrderAgainButton,
  } = usePageLayoutContext();
  const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);
  const { isLargeTablet } = useWindowDimensions();
  const headerStatesProps: HeaderStatesProps = getCurrentPageHeaderStates();

  const { isSearchFieldVisible, setIsSearchFieldVisible } =
    useHomePageContext();

  const onClickCart = () => dispatch({ type: 'TOGGLE_CART', value: true });
  const accountButtonHandler = () => navigateTo({ route: pages.MY_ACCOUNT });
  const loginButtonHandler = () => openLoginDialog();
  const backwardButtonHandler = async () => await back();

  const renderRightButtonOnMobile = () => {
    return (
      isSidebarNavOpen && <IconButton onClick={closeSidebarNav} icon={<X />} />
    );
  };
  const isWithBodySearchField =
    isCurrentPage(pages.HOME) || isCurrentPage(pages.SEARCH);

  React.useEffect(() => {
    const isBodySearchFieldVisible =
      !headerStatesProps.searchField || isWithBodySearchField;
    setIsSearchFieldVisible(isBodySearchFieldVisible);
  }, [isWithBodySearchField]);

  const renderActionButton = () => {
    return authenticated ? (
      [
        <Button
          data-testid={ids.accountActionsButton}
          key={'1'}
          title="ACCOUNT"
          variant={ButtonVariantEnum.NEGATIVE}
          size={ButtonSizeEnum.MEDIUM}
          onClick={accountButtonHandler}
        />,
        <IconButton
          data-testid={ids.accountActionsButton}
          key={'2'}
          icon={<User />}
          onClick={accountButtonHandler}
        />,
      ]
    ) : (
      <Button
        data-testid={ids.accountActionsButton}
        title="LOGIN"
        onClick={loginButtonHandler}
        variant={ButtonVariantEnum.NEGATIVE}
      />
    );
  };

  const renderActionButtonForBottomNav = () => {
    return authenticated ? (
      <Button
        data-testid={ids.accountActionsButton}
        isColumnAligned
        icon={<User />}
        variant={ButtonVariantEnum.NEGATIVE}
        onClick={accountButtonHandler}
        title="Account"
      />
    ) : (
      <Button
        data-testid={ids.accountActionsButton}
        isColumnAligned
        icon={<User />}
        variant={ButtonVariantEnum.NEGATIVE}
        onClick={loginButtonHandler}
        title="Login"
      />
    );
  };

  const navigateToPersonalShoppingInfo = (page: string) => {
    if (!authenticated) {
      openLoginDialog();
    } else {
      setLoading(true);
      navigateTo({ route: page });
    }
  };

  const { isChannelSelected } = useChannelContext();

  const scrollToTopPage = () => scrollToTheTopOfTheElement('pageBodyContainer');

  const isOrderDetailsOnMobile =
    (isCurrentPage(pages.ORDER_DETAILS) ||
      isCurrentPage(pages.SHOPPING_LIST_DETAILS)) &&
    isLargeTablet;
  const isCheckoutDetailsOnMobile =
    isCurrentPage(pages.CHECKOUT) && isLargeTablet;

  const isShowCartButtonOnMobile =
    isOrderDetailsOnMobile && !showOrderAgainButton && isChannelSelected;

  const isShowBottomNav =
    isLargeTablet &&
    (!isOrderDetailsOnMobile || !showOrderAgainButton) &&
    !isCheckoutDetailsOnMobile;
  const ref = useRef();
  const getSearchField = () => {
    if (headerStatesProps.searchField) {
      return (
        <SearchBar
          key={'inHeader'}
          isInHeader
          // stretch
          isWithStrip={false}
          dataTestId={headerIds.mainSearchInput}
          variant={SearchFieldVariantEnum.DENSE}
        />
      );
    }
    return null;
  };
  return (
    <div
      ref={ref}
      style={{ height: 'inherit' }}
      onClick={(event) => {
        if (
          [
            event.target,
            (event.target as any).parentElement,
            (event.target as any).parentElement.parentElement,
          ].includes(ref.current)
        ) {
          scrollToTopPage();
        }
      }}>
      <Dialog
        title="search"
        isOpen={isSearchModalOpen && isLargeTablet}
        onClose={() => setIsSearchModalOpen(false)}>
        <Dialog.Body>
          <SearchBar
            onClick={() => setIsSearchModalOpen(false)}
            variant={SearchFieldVariantEnum.DENSE}
            isSearchPage
          />
        </Dialog.Body>
      </Dialog>
      <BottomNavBar
        onHomeClick={() => {
          if (isCurrentPage(pages.HOME)) {
            scrollToTopPage();
          } else {
            navigateTo({
              route: pages.HOME,
              options: { scroll: true },
            });
          }
        }}
        onMenuClick={openSidebarNav}
        onLoginClick={openLoginDialog}
        // onSearchClick={() => setIsSearchModalOpen(true)}
        onOrdersClick={() =>
          navigateToPersonalShoppingInfo(pages.ORDER_HISTORY)
        }
        onListsClick={() =>
          navigateToPersonalShoppingInfo(pages.SHOPPING_LISTS)
        }
        isMenuSelected={isPageSidebarNavOpen}
        isHomeSelected={isCurrentPage(pages.HOME)}
        isOrdersSelected={isCurrentPage(pages.ORDER_HISTORY)}
        isListsSelected={isCurrentPage(pages.SHOPPING_LISTS)}
        // isSearchSelected={isSearchModalOpen}
        // onAccountClick={() => navigateTo({ route: pages.MY_ACCOUNT })}
        isLoggedIn={authenticated}
        onCartClick={onClickCart}
        show={isShowBottomNav}
        cartItemsQuantity={defaultCheckoutCount}
        rightButton={renderActionButtonForBottomNav()}
        leftButton={
          <Button
            isColumnAligned
            variant={ButtonVariantEnum.NEGATIVE}
            onClick={openSidebarNav}
            icon={<Menu />}
            title="Browse"
          />
        }
      />
      <HeaderComponent
        loginButtonTestId={ids.accountActionsButton}
        progressBarDataTestId={pageLayoutIds.progressBar}
        onBackClick={backwardButtonHandler}
        onLoginClick={openLoginDialog}
        showLoginButton={isLargeTablet && !authenticated}
        onAccountClick={() => navigateTo({ route: pages.MY_ACCOUNT })}
        isLoggedIn={authenticated}
        loading={isLoading}
        onMenuClick={openSidebarNav}
        showHeaderSearch={!isSearchFieldVisible && isChannelSelected}
        showMenu={isChannelSelected}
        // showHeaderSearch={!isCurrentPage(pages.HOME) || !isSearchFieldVisible}
        // isSearchFieldVisible={isSearchFieldVisible && }
        // isMobileHomePage={true}
        showLogo={headerStatesProps.logo}
        isWithNavbar={false}
        onCartClick={onClickCart}
        cartItemsQuantity={defaultCheckoutCount}
        onLogoClick={() => navigateTo({ route: pages.HOME })}
        rightButtonOnMobile={renderRightButtonOnMobile()}
        onSearchButtonClick={() => setIsSearchModalOpen(true)}
        searchField={getSearchField()}
        showCartButton={headerStatesProps.cartButton}
        showCartButtonOnMobile={!isLargeTablet && isShowCartButtonOnMobile}
        showBackButton={headerStatesProps.backward}
        // backwardButton={
        //   headerStatesProps.backward ? (
        //     <IconButton
        //       data-testid={headerIds.arrowBackButton}
        //       icon={<ArrowLeft />}
        //       onClick={backwardButtonHandler}
        //     />
        //   ) : null
        // }
        // actionButtons={
        //   headerStatesProps.actionButtons && !isLargeTablet
        //     ? renderActionButton()
        //     : null
        // }
        deliveryTimeButton={
          <DeliveryInHeader show={headerStatesProps.deliveryInHeader} />
        }
      />
    </div>
  );
}
