import { StoredPaymentSources_me_storedPaymentSources } from 'teddly-sdk/lib/queries/gqlTypes/StoredPaymentSources';

import {
  Trash2 as TrashIcon,
  InfiniteList,
  UserInfoBlock,
  Button,
  ButtonVariantEnum,
  ButtonSizeEnum,
  IconButton,
} from '@teddly/teddly-ui-components';
import { css } from 'twin.macro';
import { ids } from '../../../../../cypress/pages/checkout/CheckoutPage.cy';
import styles from './style.module.scss';
import { Address } from 'teddly-sdk';
import useWindowDimensions from '@hooks/useWindowDimension';
import React from 'react';
import { StripeCardData } from '../hooks';
import { UserAddressType } from '@context/UserAddressContext';

type Methods = StripeCardData[] | UserAddressType[];

type MethodsListProps = {
  blockTitle: string;
  descriptionFormatter: (object: any) => string;
  methods?: Methods;
  onSelectCard?: (method) => Promise<void>;
  onEditCard?: (addressToEdit?: UserAddressType) => void;
  onDeleteCard?: (id: string) => Promise<void>;
  selectedMethodId?: string;
  deletedMethodIds?: string[];
};

export default function MethodsList({
  blockTitle,
  descriptionFormatter,
  methods = [],
  onSelectCard,
  onEditCard,
  onDeleteCard,
  selectedMethodId,
  deletedMethodIds,
  ...otherProps
}: MethodsListProps) {
  const renderButtons = (method) => {
    const buttons = [];
    if (onEditCard) {
      buttons.push(
        <Button
          title="Edit"
          variant={ButtonVariantEnum.OUTLINE}
          size={ButtonSizeEnum.SMALL}
          onClick={async (e) => {
            e.stopPropagation();
            await onEditCard(method);
          }}
        />,
      );
    }
    if (onDeleteCard) {
      const id = method?.creditCardInfo?.cardId ? method.creditCardInfo.cardId : method.id;
      const loading = deletedMethodIds.includes(id);
      buttons.push(
        <IconButton
          loading={loading}
          onClick={(e) => {
            e.stopPropagation();
            onDeleteCard(id);
          }}
          icon={<TrashIcon />}
        />,
      );
    }

    return buttons;
  };

  const { isLargeTablet } = useWindowDimensions();

  const renderHandler = (c: StripeCardData | UserAddressType, index) => {
    const method = c as any;
    return (
      <UserInfoBlock
        // dataTestId
        key={index}
        title={method?.tag?.name || blockTitle}
        data-testid={ids.shippingAddressName + method?.firstName}
        description={descriptionFormatter(method)}
        className={!isLargeTablet ? styles.userInfoMethodBlock : null}
        buttons={renderButtons(method)}
        onClick={() => onSelectCard(method)}
        selected={selectedMethodId && selectedMethodId == (method?.id ? method.id : method?.creditCardInfo?.cardId)}
      />
    );
  };

  // console.log(otherProps);

  return (
    methods && (
      <InfiniteList
        {...otherProps}
        css={css`
          gap: 24px;
        `}
        items={methods}
        rowRender={renderHandler}
      />
    )
  );
}
