import { NewVariant } from '@interfaces';

export type CartState = {
  variants: { [key: string]: NewVariant };
  lastVariantAdded?: NewVariant;
  orderHistoryCount?: number;
};

export type CartAction =
  | {
      type: 'ADD_VARIANT';
      variant: NewVariant;
      editingOrder: any;
    }
  | {
      type: 'ADD_VARIANTS';
      variants: NewVariant[];
    }
  | {
      type: 'UPDATE_VARIANTS_IN_LOCAL';
      newState: CartState;
    }
  | {
      type: 'UPDATE_VARIANT';
      variant: NewVariant;
    }
  | {
      type: 'REMOVE_VARIANT';
      variantId: string;
    }
  | {
      type: 'SUBTRACT_VARIANT';
      variantId: string;
    }
  | {
      type: 'ORDER_HISTORY_COUNT';
      totalHistoryVariants: number | null;
    }
  | {
      type: 'EMPTY_CART';
    }
  | {
      type: 'CLEAR_LAST_VARIANT_ADDED';
    };

export const initialState: CartState = {
  variants: {},
  lastVariantAdded: null,
};

export default function cartReducer(
  state: CartState = initialState,
  action: CartAction,
): CartState {
  if (!action) return state;
  const { variant } = action as any;
  switch (action.type) {
    case 'UPDATE_VARIANTS_IN_LOCAL': {
      const { newState } = action;
      // const oldData = state.variants[variant.id];
      return newState;
    }
    case 'ADD_VARIANT': {
      const { variant } = action;
      let newState: CartState = { ...state, variants: {} };
      newState = {
        ...state,
        variants: {
          ...state.variants,
          [variant.id]: {
            ...variant,
          },
        },
        lastVariantAdded: variant,
      };
      // }
      return newState;
    }

    case 'ADD_VARIANTS': {
      const { variants } = action;
      let bulkVariants = {};
      for (const variant of variants as NewVariant[]) {
        const oldData = state.variants[variant.id];
        bulkVariants[variant.id] = variant;
      }
      return {
        ...state,
        variants: {
          ...state.variants,
          ...bulkVariants,
        },
      };
    }

    case 'ORDER_HISTORY_COUNT': {
      const { totalHistoryVariants } = action;
      return {
        ...state,
        orderHistoryCount: totalHistoryVariants,
      };
    }

    case 'CLEAR_LAST_VARIANT_ADDED': {
      return {
        ...state,
        lastVariantAdded: null,
      };
    }

    case 'SUBTRACT_VARIANT': {
      const { variantId } = action;
      const existingProduct = state.variants[variantId];
      if (!existingProduct) return state;

      const toDelete = existingProduct.line.quantity === 1;
      const { variants } = state;
      if (toDelete) {
        delete variants[variantId];
      } else {
        variants[variantId] = {
          ...variants[variantId],
          line: {
            quantity: variants[variantId].line.quantity - 1,
            note: variants[variantId].line.note,
            isInclude: variants[variantId].line.isInclude,
          },
        };
      }
      return {
        variants,
      };
    }
    case 'REMOVE_VARIANT': {
      const { variantId } = action;
      const existingProduct = state.variants[variantId];
      if (!existingProduct) return state;

      const { variants } = state;
      delete variants[variantId];

      return {
        variants: variants,
      };
    }
    case 'EMPTY_CART': {
      return initialState;
    }

    default:
      return state;
  }
}
