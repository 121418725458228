/**
 * TODO:
 * 1. create data-testids map (ids)
 * 2. Create getter for all of the required elements
 * 3. Create basic actions - click (button) input (type/select) assert (varifying text fields)
 */

import Utils from '../../utils/utils.cy';
import index from '../../fixtures';

export const ids = {
  snackbar: 'snackbar',
  searchInput: 'searchInput',
  splashScreen: 'splash-screen',
  progressBar: 'progress-bar',
};

export default class PageLayoutPage {
  get searchInput() {
    return cy.get(Utils.formatDataTestIdSelector(ids.searchInput));
  }

  get snackbar() {
    return cy.get(Utils.formatDataTestIdSelector(ids.snackbar), {
      timeout: index.timeouts.long,
    });
  }

  get splashScreen() {
    return cy.get(Utils.formatDataTestIdSelector(ids.splashScreen), {
      timeout: index.timeouts.xlong,
    });
  }

  get progressBar() {
    return cy.get('div.Header-module_progressBar__JCSx7', {
      timeout: index.timeouts.xlong,
    });
    // swith with this one when data test id appears.
    // return cy.get(Utils.formatDataTestIdSelector(ids.progressBar), {
    //   timeout: index.timeouts.xlong,
    // });
  }

  progressBarWait() {
    Utils.customWait(index.timeouts.minor);
    return this.progressBar.should('not.exist');
  }

  splashScreenWait() {
    Utils.customWait(index.timeouts.minor);
    return this.splashScreen.should('not.exist');
  }
  typeSearch(search: string) {
    return this.searchInput.type(search, { force: true });
  }

  clickEnterKeyOnSearchInput() {
    return this.searchInput.trigger('keypress', { keyCode: 13 });
  }

  assertSnackbar(expectedMessage: string) {
    return this.snackbar.should('be.visible').contains(expectedMessage, {
      matchCase: false,
    });
  }
}
