/**
 * TODO:
 * 1. create data-testids map (ids)
 * 2. Create getter for all of the required elements
 * 3. Create basic actions - click (button) input (type/select) assert (varifying text fields)
 */

import { BasePageObjectModel } from '../../BasePageObjectModel';

export const ids = {
  accountActionsButton: 'account-actions',
};

export default class NavbarPage extends BasePageObjectModel {
  url: string;
  title: string;

  constructor() {
    super('/index.php?route=extension/maza/blog/home', 'Blog');
  }

  get accountActionsButton() {
    return cy.get(`[data-testid=${ids.accountActionsButton}]`);
  }

  clickAccountActionsButton() {
    return this.accountActionsButton.click( {force: true , multiple: true }  );
  }

  assertAccountActions(expectedMessage) {
    return this.accountActionsButton.contains(expectedMessage, {
      matchCase: false,
    });
  }
}
