import { Timeslot } from '@app/checkout';
import {
  useContext,
  createContext,
  ReactNode,
  useEffect,
  useState,
} from 'react';
import {
  AvailableTimeslotsQuery,
  useAuth,
  useAvailableTimeslotsLazyQuery,
} from 'teddly-sdk';
import { useCheckoutFlowContext } from './CheckoutFlowContext';
import { useCheckoutShippingAddressContext } from './CheckoutShippingAddressContext';
import {
  getTimeslotStartMoment,
  isSameTimeslot,
  validateSelectedTimeslot,
} from '../components/Pages/Checkout/components/Delivery/utils';

const DeliveryContext = createContext<{
  availableTimeSlot: Map<string, AvailableTimeslotsQuery>;
  selectedTimeslot: Timeslot;
  isTimeslotNeeded: boolean;
  isTimeslotValid: boolean;
  onTimeslotSet: (timeslot: Timeslot, timeslotNeeded?: boolean) => void;
  timeslotForServerRequest: Date;
  setIsTimeslotValid: (valid: boolean) => void;
  setSelectedTimeslot: (timeslot: Timeslot) => void;
}>(null);

export function DeliveryProvider({ children }: { children: ReactNode }) {
  const { user } = useAuth();
  const userId = user ? user.id : null;
  const [availableTimeSlot, setAvailableTimeSlot] = useState<
    Map<string, AvailableTimeslotsQuery>
  >(new Map<string, AvailableTimeslotsQuery>());
  const [selectedTimeslot, setSelectedTimeslot] = useState<Timeslot>();
  const [isTimeslotNeeded, setIsTimeslotNeeded] = useState<boolean>(true);
  const [isTimeslotValid, setIsTimeslotValid] = useState<boolean>(true);
  const { checkout } = useCheckoutFlowContext();

  const { isCheckoutShippingAddressValid, availableShippingMethods } =
    useCheckoutShippingAddressContext();
  const selectTimesSlotsRefresh = async (
    availables: Map<string, AvailableTimeslotsQuery> = null,
  ) => {
    if (!availables) availables = availableTimeSlot;
    setIsTimeslotNeeded(false);
    setIsTimeslotValid(false);
    const selectedMethodTimeslotData: AvailableTimeslotsQuery = availables.get(
      checkout?.shippingMethod?.id,
    );
    if (
      selectedMethodTimeslotData &&
      !(selectedMethodTimeslotData?.availableTimeslot?.length > 0)
    ) {
      setIsTimeslotNeeded(false);
      onTimeslotSet(null, false);
    }
    if (
      selectedMethodTimeslotData?.availableTimeslot &&
      selectedMethodTimeslotData?.availableTimeslot?.length > 0
    ) {
      setIsTimeslotNeeded(true);
      if (checkout?.shippingMethod?.isFastDelivery) {
        setIsTimeslotValid(true);
        onTimeslotSet(selectedMethodTimeslotData?.availableTimeslot[0], true);
      } else setIsTimeslotNeeded(true);

      let newTimeslot: Timeslot = null;
      if (selectedTimeslot && validateSelectedTimeslot(selectedTimeslot)) {
        newTimeslot = selectedMethodTimeslotData.availableTimeslot.find(
          (value) => isSameTimeslot(value, selectedTimeslot),
        );
        onTimeslotSet(newTimeslot, true);
      }
    }
  };

  const [loadAvailableTimeslots, { data, loading, refetch }] =
    useAvailableTimeslotsLazyQuery({
      fetchPolicy: 'cache-and-network',
    });

  const timesSlotsRefresh = async () => {
    const methodsWithoutTImeslots = availableShippingMethods.filter(
      (value) => !availableTimeSlot.has(value.id),
    );
    if (methodsWithoutTImeslots.length === 0) return;
    const newAvailableTimeSlot = new Map<string, AvailableTimeslotsQuery>(
      availableTimeSlot,
    );
    for (let index = 0; index < methodsWithoutTImeslots.length; index++) {
      const element = methodsWithoutTImeslots[index];
      const { data } = await loadAvailableTimeslots({
        variables: { shippingMethodId: element?.id },
        fetchPolicy: 'cache-and-network',
      });
      if (data) newAvailableTimeSlot.set(element?.id, data);
    }
    if (newAvailableTimeSlot.size != availableTimeSlot.size) {
      setAvailableTimeSlot(newAvailableTimeSlot);
      selectTimesSlotsRefresh(newAvailableTimeSlot);
    }
  };

  const onTimeslotSet = (timeslot: Timeslot, timeslotNeeded?: boolean) => {
    timeslotNeeded = timeslotNeeded == null ? isTimeslotNeeded : timeslotNeeded;
    setSelectedTimeslot(timeslot);
    setIsTimeslotValid(true);
    const value =
      timeslotNeeded && timeslot
        ? getTimeslotStartMoment(timeslot).toDate()
        : null;
  };
  const resetDelivery = () => {
    setSelectedTimeslot(null);
    setIsTimeslotNeeded(false);
    setIsTimeslotValid(false);
  };
  // if there is no user => if sign out or reload the web site
  // then set the delivery  that is not valid
  useEffect(() => {
    if (!userId) {
      resetDelivery();
    }
  }, [userId]);

  useEffect(() => {
    if (userId && checkout?.channel?.id && availableShippingMethods) {
      timesSlotsRefresh();
    }

    if (
      userId &&
      checkout?.shippingMethod?.id &&
      checkout?.channel?.id &&
      isCheckoutShippingAddressValid
    ) {
      selectTimesSlotsRefresh();
    } else {
      resetDelivery();
    }
  }, [checkout?.shippingMethod?.id, isCheckoutShippingAddressValid, userId]);

  useEffect(() => {
    if (
      userId &&
      checkout?.channel?.id &&
      availableShippingMethods &&
      availableShippingMethods.find((v) => !availableTimeSlot.has(v.id))
    ) {
      timesSlotsRefresh();
    }
  }, [checkout?.availableShippingMethods]);

  const timeslotForServerRequest: Date = selectedTimeslot
    ? getTimeslotStartMoment(selectedTimeslot).toDate()
    : null;

  return (
    <DeliveryContext.Provider
      value={{
        availableTimeSlot: availableTimeSlot,
        selectedTimeslot: selectedTimeslot,
        isTimeslotNeeded: isTimeslotNeeded,
        isTimeslotValid: isTimeslotValid,
        onTimeslotSet: onTimeslotSet,
        timeslotForServerRequest: timeslotForServerRequest,
        setIsTimeslotValid: setIsTimeslotValid,
        setSelectedTimeslot: setSelectedTimeslot,
      }}>
      {children}
    </DeliveryContext.Provider>
  );
}

export const useDeliveryContext = () => {
  const context = useContext(DeliveryContext);

  if (!context)
    throw new Error('DeliveryContext was used outside of the DeliveryProvider');

  return context;
};
