/**
 * TODO:
 * 1. create data-testids map (ids)
 * 2. Create getter for all of the required elements
 * 3. Create basic actions - click (button) input (type/select) assert (varifying text fields)
 */

import Utils from '../../utils/utils.cy';
import { BasePageObjectModel } from '../../BasePageObjectModel';
import index from '../../fixtures';
import PageLayoutPage from '../page-layout/PageLayoutPage.cy';
import LocalStorageUtils from '../../utils/localStorage.cy';
import NavbarPage from '../navbars/navbarPage.cy';

export const ids = {
  accountActionsButton: 'account-actions',
  image: 'image',
  snackbar: 'snackbar',
  forgotPasswordButton: 'forgot-password',
  dialog: 'dialog',
  heading: 'signin-heading',
  emailInput: 'signin-email',
  firstNameInput: 'signin-first-name',
  lastNameInput: 'signin-last-name',
  phoneInput: 'signin-phone',
  passwordInput: 'signin-password',
  submitButton: 'signin-submit',
  cancelButton: 'signin-cancel',
  resendEmailButton: 'signin-resend-email',
  googleSocialLoginButton: 'google-social-login',
  appleSocialLoginButton: 'apple-social-login',
};

export default class SignInDialogPage extends BasePageObjectModel {
  url: string;
  title: string;

  pageLayoutPage = new PageLayoutPage();
  navbarPage = new NavbarPage();
  localStorageUtils = new LocalStorageUtils();

  constructor() {
    super('/index.php?route=extension/maza/blog/home', 'Blog');
  }

  get dialog() {
    return cy.get(Utils.formatDataTestIdSelector(ids.dialog));
  }

  get heading() {
    return cy.get(Utils.formatDataTestIdSelector(ids.heading));
  }

  get emailInput() {
    return cy.get(Utils.formatDataTestIdSelector(ids.emailInput));
  }

  get firstNameInput() {
    return cy.get(Utils.formatDataTestIdSelector(ids.firstNameInput));
  }

  get lastNameInput() {
    return cy.get(Utils.formatDataTestIdSelector(ids.lastNameInput));
  }

  get phoneInput() {
    return cy.get(Utils.formatDataTestIdSelector(ids.phoneInput));
  }

  get passwordInput() {
    return cy.get(Utils.formatDataTestIdSelector(ids.passwordInput));
  }

  get submitButton() {
    return cy.get(Utils.formatDataTestIdSelector(ids.submitButton), {
      timeout: 180000,
    });
  }

  get cancelButton() {
    return cy.get(Utils.formatDataTestIdSelector(ids.cancelButton));
  }

  get image() {
    return cy.get(Utils.formatDataTestIdSelector(ids.image));
  }

  get forgotPasswordButton() {
    return cy.get(Utils.formatDataTestIdSelector(ids.forgotPasswordButton));
  }

  get googleButton() {
    return cy.get(Utils.formatDataTestIdSelector(ids.googleSocialLoginButton));
  }

  get appleButton() {
    return cy.get(Utils.formatDataTestIdSelector(ids.appleSocialLoginButton));
  }

  get errorMessage() {
    return cy.get('span');
  }

  get successMessage() {
    return cy.get('p');
  }

  signIn() {
    cy.fixture(index.account.existing).then(({ email, password }) => {
      this.typeEmail(email);
      this.typePassword(password);
    });
    this.clickSubmitButton().then(() => {
      Utils.awaitGraphqlRequest('TokenAuth', () => {
        this.pageLayoutPage.assertSnackbar('Welcome Back!');
        this.dialog.should('not.exist').then(() => {
          this.navbarPage.assertAccountActions('account');
          Utils.shouldNotBeEmptyString(
            LocalStorageUtils.getLocalStorageValueBy('token'),
          );
        });
      });
    });
  }

  assertHeadingText(expectedText) {
    return this.heading.should('contain', expectedText);
  }

  typeEmail(email) {
    return this.emailInput.type(email, { force: true });
  }

  typePassword(password) {
    return this.passwordInput.type(password, { force: true });
  }

  clickForgotPasswordButton() {
    return this.forgotPasswordButton.click({ force: true });
  }

  clickSubmitButton() {
    return this.submitButton
      .should('be.enabled')
      .click({ force: true, multiple: true });
  }

  clickCancelButton() {
    return this.cancelButton.click({ force: true });
  }

  assertErrorMessage(field, expectedErrorMessage) {
    return field.parents(':lt(2)').next('label').contains(expectedErrorMessage);
  }

  assertSuccessMessage(expectedSuccessMessage) {
    return this.successMessage.should('contain', expectedSuccessMessage);
  }
}
