import '@styles/index.scss';
import 'node_modules/@teddly/teddly-ui-components/dist/index.css';
import React, { useEffect } from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { appWithTranslation } from 'next-i18next';
import nextI18NextConfig from '../../next-i18next.config';
import AppProviders from '@context/AppProviders';
import { AppProps } from 'next/dist/shared/lib/router/router';
import { GoogleTagManager } from '@next/third-parties/google';
import PageLayout from '@components/Content/PageLayout';
import { SplashScreen } from '@teddly/teddly-ui-components';
import { initialPendoAnalytics } from '@config/pendo/pendoInit';
import { ids } from '@cypress/pages/page-layout/PageLayoutPage.cy';

// import { useAuth } from 'teddly-sdk';

// if (
//   typeof window !== 'undefined' &&
//   (process.env.NODE_ENV === 'development' || window.Cypress)
// ) {

// const { worker } = require('../mocks/browser');
// worker.start();

// }

function App({ Component, pageProps }: AppProps) {
  const { optOutFromLayout, ...otherProps } = pageProps;

  useEffect(() => {});

  const [isAppLoading, setIsAppLoading] = React.useState<boolean>(true);

  useEffect(() => {
    if ('serviceWorker' in navigator) {
      window.addEventListener('load', function () {
        navigator.serviceWorker.register('/firebase-messaging-sw.js').then(
          function (registration) {
            console.log(
              'Service Worker registration successful with scope: ',
              registration.scope,
            );
          },
          function (err) {
            console.log('Service Worker registration failed: ', err);
          },
        );
      });
    }

    window.addEventListener('message', async function (event) {
      // Ensure that the message is coming from a trusted source
      // console.log(event.origin)
      // console.log(event.data)
      if (event.origin === process.env.NEXT_PUBLIC_DASHBOARD_URL) {
        const { token, csrf_token } = event.data;
        if (token && csrf_token) {
          localStorage.setItem('token', token);
          localStorage.setItem('csrf_token', csrf_token);
          window.location.reload();
        }
      }
    });
  }, []);

  const router = useRouter();

  useEffect(() => {
    if (document.readyState && router.asPath?.includes('suggestions')) {
      // if(showSplashScreen){
      //   setShowSplashScreen(false);
      // }
      // else{
      setIsAppLoading(false);
      // }
    }
  }, [typeof document !== 'undefined' ? document?.readyState : undefined]);

  useEffect(() => {
    const pendoApiKey = '1ec27af1-b5ab-4ac6-7b72-4cee6c73da74'; //Move to .env file?
    initialPendoAnalytics(pendoApiKey);
  }, []);

  return (
    <>
      {/* <SplashScreen /> */}
      <Head>
        <title>teddly</title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0"
        />
        <link rel="icon" type="image/svg+xml" href="/favicon.png" />
        <link rel="alternate icon" href="/favicon.ico" />
        <GoogleTagManager gtmId="GTM-WNJX6ZW4" />
        {/* <script
          async
          id="chatform-widget"
          src="https://app.chatform.com/chat-widget.bundle.js"
          data-company-name="Teddly"
          data-workspace-id="HZ5H2FAQpb"
          data-message-text="👋 Hey there and welcome to Teddly! How can we assist you today?"
          data-button-text="Chat"
          data-icon="https://play-lh.googleusercontent.com/Ovq14Ae5_vBMxDz7TLqzIila0gJCzqsERmMIiv_rPsMWSOzT5qjQiV7WD3PbBXlj4Q"
          data-channel="whatsapp"
        /> */}
      </Head>
      {/* <GlobalStyles /> */}
      <AppProviders>
        {isAppLoading !== false && (
          <SplashScreen dataTestid={ids.splashScreen} />
        )}
        {optOutFromLayout ? (
          <Component {...otherProps} />
        ) : (
          <PageLayout
            isAppLoading={isAppLoading}
            setIsAppLoading={setIsAppLoading}>
            <Component {...otherProps} />
          </PageLayout>
        )}
      </AppProviders>
    </>
  );
}

export default appWithTranslation(App, nextI18NextConfig);
