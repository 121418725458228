/**
 * TODO:
 * 1. create data-testids map (ids)
 * 2. Create getter for all of the required elements
 * 3. Create basic actions - click (button) input (type/select) assert (varifying text fields)
 */

import Utils from '../../utils/utils.cy';
import { BasePageObjectModel } from '../../BasePageObjectModel';

export const ids = {
  vendorLogoButton: 'vendor-logo-button', ///
  price: 'product-price', ///
  name: 'product-name', ///
  byQuantitySelector: 'by-quantity-selector',
  addToCartButton: 'add-to-cart-button', ///
  quantityController: 'quantity-controller', //
  commentToVendorButton: 'comment-to-vendor-button', //
  commentDialogSaveButton: 'comment-dialog-save-button', //
  commentDialogCancelButton: 'comment-dialog-cancel-button', //
  itemMenuButton: 'item-menu-button', //
  shareButton: 'share-button', //
  shoppingListMenuButton: 'shopping-list-menu-button', //
  createNewListButton: 'create-new-list-button', //
  shoppingLists: 'shopping-lists', //
  itemImage: 'item-image', //
  arrowLeftButton: 'arrow-left-button', //
  arrowRightButton: 'arrow-right-button', //
  breadcrumbCategories: 'breadcrumb-categories', //
  commentToVendorInput: 'comment-to-vendor-input', //
  itemDetailsMinusButton: 'counter-minus-button', ///
  fastDeliveryGroceryButton: 'fast-delivery-grocery-button', ///
  itemDetailsActionButtons: 'item-details-action-buttons',
};

export default class ItemDescriptionDialogPage extends BasePageObjectModel {
  url: string;
  title: string;

  constructor() {
    super('/index.php?route=extension/maza/blog/home', 'Blog');
  }

  get commentToVendorInput() {
    return cy.get(Utils.formatDataTestIdSelector(ids.commentToVendorInput));
  }
  get breadcrumbCategories() {
    return cy.get(Utils.formatDataTestIdSelector(ids.breadcrumbCategories));
  }

  get arrowRightButton() {
    return cy.get(Utils.formatDataTestIdSelector(ids.arrowRightButton));
  }
  get arrowLeftButton() {
    return cy.get(Utils.formatDataTestIdSelector(ids.arrowLeftButton));
  }
  get itemImage() {
    return cy.get(Utils.formatDataTestIdSelector(ids.itemImage));
  }
  get shoppingLists() {
    return cy.get(Utils.formatDataTestIdSelector(ids.shoppingLists));
  }
  get vendorLogoButton() {
    return cy.get(Utils.formatDataTestIdSelector(ids.vendorLogoButton));
  }

  get price() {
    return cy.get(Utils.formatDataTestIdSelector(ids.price));
  }

  get name() {
    return cy.get(Utils.formatDataTestIdSelector(ids.name));
  }

  get byQuantitySelector() {
    return cy.get(Utils.formatDataTestIdSelector(ids.byQuantitySelector));
  }

  get quantityController() {
    return cy.get(Utils.formatDataTestIdSelector(ids.quantityController));
  }

  get addToCartButton() {
    return cy.get(Utils.formatDataTestIdSelector(ids.addToCartButton));
  }
  get minusButton() {
    return cy
      .get(Utils.formatDataTestIdSelector(ids.itemDetailsActionButtons))
      .within(
        () => cy.get(Utils.formatDataTestIdSelector(ids.itemDetailsMinusButton)) // Only yield inputs within form
      );
  }

  get commentToVendorButton() {
    return cy.get(Utils.formatDataTestIdSelector(ids.commentToVendorButton));
  }

  get commentDialogSaveButton() {
    return cy.get(Utils.formatDataTestIdSelector(ids.commentDialogSaveButton));
  }

  get commentDialogCancelButton() {
    return cy.get(
      Utils.formatDataTestIdSelector(ids.commentDialogCancelButton)
    );
  }

  get itemMenuButton() {
    return cy.get(Utils.formatDataTestIdSelector(ids.itemMenuButton));
  }

  get shareButton() {
    return cy.get(Utils.formatDataTestIdSelector(ids.shareButton));
  }

  get shoppingListMenuButton() {
    return cy.get(Utils.formatDataTestIdSelector(ids.shoppingListMenuButton));
  }

  get createNewListButton() {
    return cy.get(Utils.formatDataTestIdSelector(ids.createNewListButton));
  }

  clickCommentToVendorButton() {
    return this.commentToVendorButton.click({ force: true });
  }

  clickCommentDialogCancelButton() {
    return this.commentDialogCancelButton.click({ force: true });
  }

  clickCommentDialogSaveButton() {
    return this.commentDialogSaveButton.click({ force: true });
  }
  clickShoppingListMenuButton() {
    return this.shoppingListMenuButton.click({ force: true });
  }

  clickShareButton() {
    return this.shareButton.click({ force: true });
  }

  clickAddToCartButton() {
    return this.addToCartButton.click({ force: true });
  }

  clickMinusQuantityButton() {
    return cy
      .get(Utils.formatDataTestIdSelector(ids.itemDetailsActionButtons))
      .within(
        () =>
          cy
            .get(Utils.formatDataTestIdSelector(ids.itemDetailsMinusButton))
            .click({ force: true }) // Only yield inputs within form
      );
  }

  clickByCuantitySelector() {
    return this.byQuantitySelector.click({ force: true });
  }

  clickByArrowRightButton() {
    return this.arrowRightButton.click({ force: true });
  }

  clickItemMenuButton() {
    return this.itemMenuButton.click({ force: true });
  }

  clickArrowLeftButton() {
    return this.arrowLeftButton.click({ force: true });
  }
  clickCreateNewShoppingListButton() {
    return this.createNewListButton.click({ force: true });
  }

  assertErrorMessage(field, expectedErrorMessage) {
    return field.parents(':lt(2)').next('label').contains(expectedErrorMessage);
  }
}
