import { useContext, createContext, ReactNode } from 'react';
import {
  useAuth,
  UpdateUserAddressMutationVariables,
  UpdateUserAddressMutation,
  CreateUserAddressMutationVariables,
  CreateUserAddressMutation,
  UserAddressesQuery,
  useDeleteUserAddresss as useDeleteUserAddressMutation,
  DeleteUserAddressMutationVariables,
  DeleteUserAddressMutation,
  useUserAddressesQuery,
} from 'teddly-sdk';

type UserAddressesResultsType = UserAddressesQuery['me'];

export type UserAddressType = UserAddressesResultsType['addresses'][0];

const UserAddressContext = createContext<{
  loaded: boolean;
  userAddresses: UserAddressType[];
  defaultShippingAddress: UserAddressType;
  defaultBillingAddress: UserAddressType;
  availableAddressTags: UserAddressesQuery['me']['availableAddressTags'];
  addAddress: (
    vars: CreateUserAddressMutationVariables,
  ) => Promise<CreateUserAddressMutation | null>;
  deleteAddress: (
    vars: DeleteUserAddressMutationVariables,
  ) => Promise<DeleteUserAddressMutation['accountAddressDelete'] | null>;
  refetchAddress: () => void;
  updateAddress: (
    vars: UpdateUserAddressMutationVariables,
  ) => Promise<UpdateUserAddressMutation | null>;
}>(null);

export function UserAddressProvider({ children }: { children: ReactNode }) {
  const { user } = useAuth();
  const [deleteAddress] = useDeleteUserAddressMutation();
  const { accountAddressUpdate, accountAddressCreate } = useAuth();

  const { data, loading, refetch, error } = useUserAddressesQuery({
    fetchPolicy: 'cache-and-network',
    skip: !user,
    variables: { onlyFromCurrentChannel: false },
  });

  const addUserAddress = async (
    vars: CreateUserAddressMutationVariables,
  ): Promise<CreateUserAddressMutation | null> => {
    try {
      const response = await accountAddressCreate(vars);
      const responseData = response?.data as CreateUserAddressMutation;
      if (responseData && responseData?.accountAddressCreate?.user?.id) {
        refetch();
      }
      return responseData;
    } catch (e) {
      return null;
    }
  };

  const updateUserAddress = async (
    vars: UpdateUserAddressMutationVariables,
  ): Promise<UpdateUserAddressMutation | null> => {
    try {
      const response = await accountAddressUpdate(vars);
      const responseData = response?.data as UpdateUserAddressMutation;
      if (responseData && responseData?.accountAddressUpdate?.user?.id) {
        refetch();
      }
      return responseData;
    } catch (e) {
      return null;
    }
  };

  const deleteUserAddress = async (
    vars: DeleteUserAddressMutationVariables,
  ): Promise<DeleteUserAddressMutation['accountAddressDelete'] | null> => {
    try {
      const response = await deleteAddress(vars);
      if (response && response?.data?.user?.id) {
        refetch();
        return response?.data as DeleteUserAddressMutation['accountAddressDelete'];
      }
      return null;
    } catch (e) {
      return null;
    }
  };

  return (
    <UserAddressContext.Provider
      value={{
        refetchAddress: refetch,
        addAddress: addUserAddress,
        deleteAddress: deleteUserAddress,
        updateAddress: updateUserAddress,
        userAddresses:
          data?.me?.addresses?.filter((add) => add?.isValidToShipping) || [],
        loaded: loading,
        defaultBillingAddress: data?.me?.defaultBillingAddress,
        defaultShippingAddress: data?.me?.defaultShippingAddress,
        availableAddressTags: data?.me?.availableAddressTags || [],
      }}>
      {children}
    </UserAddressContext.Provider>
  );
}

export const useUserAddressesContext = () => {
  const context = useContext(UserAddressContext);

  if (!context)
    throw new Error('UserContext was used outside of the UserProvider');

  return context;
};
