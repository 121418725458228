import SignInDialogPage from '../../cypress/pages/authentication/SignInDialogPage.cy';
import index from '../../cypress/fixtures';
import LocalStorageUtils from './localStorage.cy';
import PageLayoutPage from '../../cypress/pages/page-layout/PageLayoutPage.cy';
import NavbarPage from '../../cypress/pages/navbars/navbarPage.cy';
import LandingPagePage from '../../cypress/pages/landing-page/LandingPagePage.cy';

export default class Utils {
  static customWait(fixture: number) {
    cy.wait(fixture);
  }

  static isButtonDisabled(button) {
    button.should('be.disabled');
  }

  static isButtonEnabled(button) {
    button.should('be.enabled');
  }

  static formatDataTestIdSelector(id: string) {
    return `[data-testid="${id}"]`;
  }

  /* GraphQL interception methods:
Old method is intercepting and awating in the same function, as of Cypress best practices it is recommended to intercept in the beggining and await when required or expected, hence the new functions
1. Original method by Osher-retailin intercept->await->then. - need to replace where it is used
2. New Intercept only - Primary
3. case Intercept only if channel is not empty - not in use
4. Await only + then optional - Primary
*/

  // 1. Original method by Osher-retailin.
  // Intercept, Await + .then individual request - switch all to multiple request then remove this one
  static awaitGraphqlRequest(operationName: string, then: () => void) {
    cy.intercept('POST', '**/graphql/', (req) => {
      req.body.forEach((b) => {
        if (b.operationName == operationName) {
          req.alias = operationName;
        }
      });
    });
    cy.wait(`@${operationName}`, {
      timeout: index.timeouts.long,
    }).then(then);
  }

  // 2. Intercept Multiple requests
  static interceptGqlRequests(operationNames: string[]) {
    cy.intercept('POST', '**/graphql/', (req) => {
      operationNames.forEach((operationName) => {
        req.body.forEach((b) => {
          if (b.operationName === operationName) {
            req.alias = operationName;
          }
        });
      });
    });
  }

  // 3. Intercept Graphql request only if asserted channel in header - currently not in use
  static interceptGrqlheaderAndChannel(operationName: string) {
    cy.intercept('POST', '**/graphql/', (req) => {
      if (req.headers.channel && req.headers.channel !== '') {
        req.alias = operationName;
      }
    });
  }

  // 4. Await multiple intercepted requests + .then optional
  static awaitInterceptedGql(operationNames: string[], then?: () => void) {
    // Wait for all operationNames to be intercepted
    const waits = operationNames.map((operationName) =>
      cy.wait(`@${operationName}`, { timeout: index.timeouts.long }),
    );
    // Use `Promise.all` to handle waiting for all operations before triggering the next action
    Promise.all(waits).then(then);
  }

  static shouldNotBeEmptyString(str: string | null) {
    return expect(str).to.not.be.empty;
  }

  static shouldBeEmptyString(str: string) {
    return expect(str).to.equal('');
  }

  static shouldBeNull(element) {
    return expect(element).to.null;
  }

  // Refactored with new Graphql interceptor & loading elements wait no manual waits
  static loginUsingSignInForum(channelFixture: string, accountFixture: string) {
    const pageLayoutPage = new PageLayoutPage();
    const navbarPage = new NavbarPage();
    const signInDialogPage = new SignInDialogPage();

    Utils.interceptGqlRequests(['TokenAuth']);
    cy.visit('/');
    pageLayoutPage.splashScreenWait();
    Utils.channelSelector(channelFixture);
    navbarPage.clickAccountActionsButton();
    pageLayoutPage.splashScreenWait();
    cy.fixture(accountFixture).then(({ email, password }) => {
      signInDialogPage.typeEmail(email);
      signInDialogPage.typePassword(password);
    });
    signInDialogPage.clickSubmitButton();
    Utils.awaitInterceptedGql(['TokenAuth']);
    pageLayoutPage.assertSnackbar('Welcome Back!');
    pageLayoutPage.progressBarWait();
    signInDialogPage.dialog.should('not.exist').then(() => {
      navbarPage.assertAccountActions('account');
      Utils.shouldNotBeEmptyString(
        LocalStorageUtils.getLocalStorageValueBy('token') ?? '',
      );
    });
  }

  static loginUsingLocalStorage() {
    cy.fixture(index.loginStates.emptyCart).then((state) => {
      console.info(state);
      Object.keys(state).forEach((key) => {
        const value = state[key];
        LocalStorageUtils.setLocalStorageItem(key, value);
      });
    });
  }

  static loginUsingLocalStorageMyNameUser() {
    cy.fixture(index.loginStates.emptyOrders).then((state) => {
      console.info(state);
      Object.keys(state).forEach((key) => {
        const value = state[key];
        LocalStorageUtils.setLocalStorageItem(key, value);
      });
    });
  }

  static loginUsingLocalStoragCheckoutUser() {
    cy.fixture(index.loginStates.addressUser).then((state) => {
      console.info(state);
      Object.keys(state).forEach((key) => {
        const value = state[key];
        LocalStorageUtils.setLocalStorageItem(key, value);
      });
    });
  }

  static channelSelector(fixture: string) {
    const landingPagePage = new LandingPagePage();
    const pageLayoutPage = new PageLayoutPage();
    Utils.interceptGqlRequests(['ChannelByPostalCode']);
    cy.fixture(fixture).then(({ zipCode }) => {
      landingPagePage.channelZipcodeInput.type(zipCode, { force: true });
      landingPagePage.channelZipcodeButton.click({ force: true });
    });
    Utils.awaitInterceptedGql(['ChannelByPostalCode']);
    pageLayoutPage.progressBarWait();
  }
}
