/**
 * TODO:
 * 1. create data-testids map (ids)
 * 2. Create getter for all of the required elements
 * 3. Create basic actions - click (button) input (type/select) assert (varifying text fields)
 */

import Utils from '../../utils/utils.cy';

export const ids = {
  sideNavbar: 'side-navbar',
  categories: 'categories',
  options: 'options',
};

export default class SideNavbarPage {
  get sideNavbar() {
    return cy.get(Utils.formatDataTestIdSelector(ids.sideNavbar));
  }

  get categories() {
    return cy.get(Utils.formatDataTestIdSelector(ids.categories));
  }

  get options() {
    return cy.get(Utils.formatDataTestIdSelector(ids.options));
  }

  openSideNavbar() {
    // return this.sideNavbar.invoke('hover');
    // return this.sideNavbar.click({ force: true });
  }

  getAllCategoriesTabs() {
    return this.categories.find('div');
  }

  getAllOptionsTabs() {
    return this.options.find('div');
  }
}
